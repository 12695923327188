export const HOME_ROUTE = '/';
export const LOGIN_ROUTE = '/login';
export const REGISTER_ROUTE = '/register';
export const SITES_ROUTE = '/sites';
export const DOMAINS_ROUTE = '/domains';
export const DASHBOARD_ROUTE = '/dashboard';
export const USERS_ROUTE = 'users';
export const CREATE_HOSTING_ROUTE = 'hosting/create';
export const INDEX_HOSTING_ROUTE = 'hosting/index';
export const UPDATE_HOSTING_ROUTE = 'hosting/update';
export const SHOW_HOSTING_ROUTE = 'hosting/show';
export const INDEX_DOMAIN_ROUTE = 'domain/index';
export const INDEX_DOMAIN_REG_ROUTE = 'domain/reg/index';
export const CREATE_DOMAIN_ROUTE = 'domain/create';
export const UPDATE_DOMAIN_ROUTE = 'domain/update';
export const SHOW_DOMAIN_ROUTE = 'domain/show';
export const CREATE_DOMAIN_REG_ROUTE = 'domain/reg/create';
export const UPDATE_DOMAIN_REG_ROUTE = 'domain/reg/update';
export const SHOW_DOMAIN_REG_ROUTE = 'domain/reg/show';
export const INDEX_SERVICES_ROUTE = 'services/index';
export const CREATE_SERVICES_ROUTE = 'services/create';
export const UPDATE_SERVICES_ROUTE = 'services/update';
export const INDEX_CUSTOMER_SERVICES_ROUTE = 'customer/services/index';
export const CREATE_CUSTOMER_SERVICES_ROUTE = 'customer/services/create';
export const SHOW_CUSTOMER_SERVICES_ROUTE = 'customer/services/show';
export const INDEX_ORDERS_ROUTE = 'orders/index';
export const SHOW_ORDERS_ROUTE = 'orders/show';
export const UPDATE_ORDERS_ROUTE = 'orders/update';
export const CREATE_COMMENT_ORDER_SERVICE_ROUTE = 'orders/service/comment/create';
export const SHOW_COMMENT_ORDER_SERVICE_ROUTE = 'orders/service/comment/show';
export const CREATE_INVOICE_ORDER_SERVICE_ROUTE = 'orders/service/invoice/create';
export const INDEX_INVOICES_ROUTE = 'invoices/index';
export const UPDATE_INVOICES_ROUTE = 'invoices/update';
export const SELECT_INVOICES_ROUTE = 'invoices/select';
export const INDEX_CUSTOMER_DOMAIN_ROUTE = 'customer/domain/index';
export const SHOW_CUSTOMER_DOMAIN_ROUTE = 'customer/domain/show';
export const WHOIS_CUSTOMER_DOMAIN_ROUTE = 'customer/domain/whois';
export const SHOW_ORDER_DOMAIN_ROUTE = 'orders/domain/show';
export const UPDATE_ORDER_DOMAIN_ROUTE = 'orders/domain/update';
export const INDEX_CUSTOMER_HOSTING_ROUTE = 'customer/hosting/index';
export const CREATE_CUSTOMER_HOSTING_ROUTE = 'customer/hosting/create';
export const INDEX_TICKETS_ROUTE = 'tickets/index';
export const CREATE_TICKETS_ROUTE = 'tickets/create';
export const SHOW_TICKETS_ROUTE = 'tickets/show';
export const INDEX_CUSTOMER_PROFILE_ROUTE = 'customer/profile/index';
export const SHOW_CUSTOMER_HOSTING_ROUTE = 'customer/hosting/show';

