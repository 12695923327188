import HomeHeader from "../components/HomeHeader";
import React from "react";

const Domains = () => {
    return(
        <>
            <HomeHeader/>
            <div className="container">
                <h1>Domains</h1>
            </div>
        </>

    );
};

export default Domains;