import HomeHeader from "../components/HomeHeader";
import React from "react";

const Home = () => {

     return (
         <>
             <HomeHeader/>
             <div className="container">
                 <h1>Home</h1>
             </div>
         </>

     );
}

export default Home;
